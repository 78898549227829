body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #282828;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #383838;
}

input[type="color"] {
  color-scheme: dark;
}

@media (prefers-color-scheme: dark) {
  body {background:black; color:#e0e0e0; }
}

@font-face {
  font-family: 'Attack Graffiti';
  src: local('Attack Graffiti'), url(./assets/fonts/aAttackGraffiti.otf) format('opentype');
}

@font-face {
  font-family: 'American Captain';
  src: local('American Captain'), url(./assets/fonts/American_Captain_0.otf) format('opentype');
}

@font-face {
  font-family: 'Andreas';
  src: local('Andreas'), url(./assets/fonts/Andreas.ttf) format('truetype');
}

@font-face {
  font-family: 'Batman Forever';
  src: local('Batman Forever'), url(./assets/fonts/batmfa.ttf) format('truetype');
}

@font-face {
  font-family: 'Bellyn Italic';
  src: local('Bellyn Italic'), url(./assets/fonts/Bellyn_Italic.otf) format('opentype');
}

@font-face {
  font-family: 'Blank River';
  src: local('Blank River'), url(./assets/fonts/Blank_River.ttf) format('truetype');
}

@font-face {
  font-family: 'Boldness';
  src: local('Boldness'), url(./assets/fonts/Boldness_0.otf) format('opentype');
}

@font-face {
  font-family: 'Circus Of Innocents';
  src: local('Circus Of Innocents'), url(./assets/fonts/Circus_Of_Innocents.ttf) format('truetype');
}

@font-face {
  font-family: 'College Halo';
  src: local('College Halo'), url(./assets/fonts/College_Halo.ttf) format('truetype');
}

@font-face {
  font-family: 'Desonanz';
  src: local('Desonanz'), url(./assets/fonts/desonanz.ttf) format('truetype');
}

@font-face {
  font-family: 'Distortion of the Brain and Mind';
  src: local('Distortion of the Brain and Mind'), url(./assets/fonts/Distortion_of_the_Brain_and_Mind.ttf) format('truetype');
}

@font-face {
  font-family: 'Doctor Glitch';
  src: local('Doctor Glitch'), url(./assets/fonts/Doctor_Glitch.otf) format('opentype');
}

@font-face {
  font-family: 'EthnocentricRg italic';
  src: local('ethnocentric rg it'), url(./assets/fonts/ethnocentric_rg_it.ttf) format('truetype');
}

@font-face {
  font-family: 'EthnocentricRg';
  src: local('ethnocentric rg'), url(./assets/fonts/ethnocentric_rg.ttf) format('truetype');
}

@font-face {
  font-family: 'Flash Rogers';
  src: local('Flash Rogers'), url(./assets/fonts/flashrogers.ttf) format('truetype');
}

@font-face {
  font-family: 'Higher';
  src: local('Higher'), url(./assets/fonts/Higher.otf) format('opentype');
}

@font-face {
  font-family: 'Justice Leage';
  src: local('Justice Leage'), url(./assets/fonts/JUSTICE_LEAGUE.ttf) format('truetype');
}

@font-face {
  font-family: 'Masker Area';
  src: local('Masker Area'), url(./assets/fonts/Masker_Area.otf) format('opentype');
}

@font-face {
  font-family: 'Monoton';
  src: local('Monoton'), url(./assets/fonts/Monoton-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Network';
  src: local('Network'), url(./assets/fonts/Network.ttf) format('truetype');
}

@font-face {
  font-family: 'NFL RedZone';
  src: local('NFL RedZone'), url(./assets/fonts/NFL_RedZone_0.ttf) format('truetype');
}

@font-face {
  font-family: 'Octuple';
  src: local('Octuple'), url(./assets/fonts/Octuple-max-demo_0.ttf) format('truetype');
}

@font-face {
  font-family: 'PaybAck';
  src: local('PaybAck'), url(./assets/fonts/PaybAck.ttf) format('truetype');
}

@font-face {
  font-family: 'Raceline Demo';
  src: local('Raceline Demo'), url(./assets/fonts/Raceline_Demo.otf) format('opentype');
}

@font-face {
  font-family: 'Raider Crusader';
  src: local('Raider Crusader'), url(./assets/fonts/raidercrusader_0.ttf) format('truetype');
}

@font-face {
  font-family: 'Raider Crusader Condensed';
  src: local('Raider Crusader Condensed'), url(./assets/fonts/raidercrusadercond_0.ttf) format('truetype');
}

@font-face {
  font-family: 'Raider Crusader Expanded';
  src: local('Raider Crusader Expanded'), url(./assets/fonts/raidercrusaderexpand_0.ttf) format('truetype');
}

@font-face {
  font-family: 'Raider Crusader Italic';
  src: local('Raider Crusader Italic'), url(./assets/fonts/raidercrusaderital_0.ttf) format('truetype');
}

@font-face {
  font-family: 'Redford BV';
  src: local('Redford BV'), url(./assets/fonts/Redford_BV.TTF) format('truetype');
}

@font-face {
  font-family: 'Sarcaland Brusher';
  src: local('Sarcaland Brusher'), url(./assets/fonts/Sarcaland_Brusher.otf) format('opentype');
}

@font-face {
  font-family: 'Snickers';
  src: local('Snickers'), url(./assets/fonts/Snickers.TTF) format('truetype');
}

@font-face {
  font-family: 'Space Wham';
  src: local('Space Wham'), url(./assets/fonts/space-wham.ttf) format('truetype');
}

@font-face {
  font-family: 'Sportsquake';
  src: local('Sportsquake'), url(./assets/fonts/Sportsquake.otf) format('opentype');
}

@font-face {
  font-family: 'Street Ruler';
  src: local('Street Ruler'), url(./assets/fonts/Street_Ruler.ttf) format('truetype');
}

@font-face {
  font-family: 'Thorsley';
  src: local('Thorsley'), url(./assets/fonts/Thorsley.ttf) format('truetype');
}

@font-face {
  font-family: 'Tiger Walk';
  src: local('Tiger Walk'), url(./assets/fonts/Tiger_Walk.ttf) format('truetype');
}

@font-face {
  font-family: 'Tunning Oxide';
  src: local('Tunning Oxide'), url(./assets/fonts/Tunning-OxideBold.ttf) format('truetype');
}

@font-face {
  font-family: 'X-Heighting';
  src: local('X-Heighting'), url(./assets/fonts/X-Heighting.otf) format('opentype');
}